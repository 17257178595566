import {default as Spinner} from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";
import {Col, Row} from "react-bootstrap";

interface Props {
    width?: number;
    height?: number;
    withContainer?: boolean;
}

export default class Loader extends React.Component<Props> {
    render() {

        const width = (typeof this.props.width === 'undefined') ? 50 : this.props.width;
        const height = (typeof this.props.height === 'undefined') ? 50 : this.props.height;
        const withContainer = (typeof this.props.withContainer === 'undefined') ? true : this.props.withContainer;

        if (withContainer) {
            return (
                <Row>
                    <Col className={"text-center m-5"}>
                        <Spinner
                            type="Oval"
                            color="#29BFFF"
                            height={height}
                            width={width}
                            // timeout={3000}
                        />
                        {/*wczytywanie...*/}
                    </Col>
                </Row>
            );
        }

        return (
            <Spinner
                type="TailSpin"
                color="#29BFFF"
                height={20}
                width={20}
            />
        );
    }
}
