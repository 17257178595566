import React, {Component, ComponentProps} from "react";
import {Alert, Table} from "react-bootstrap";
import {Redirect} from "react-router";
import UserService from "../utils/UserService";

interface Props extends ComponentProps<any> {
    data: {}[],
    columns: DataTableColumnDefinition[],
    actions?: DataTableActionDefinition[],
    emptyTableMessage?: string;
    rowClickLink?: (row: any) => string;
    rowClickEvent?: (row: any) => void;
    show?: boolean,
}

export interface DataTableColumnDefinition {
    header: string|JSX.Element;
    accessor: string;
    formatter?: (value: any) => void,
    customValue?: (row: any) => void,
    visibleForUserRole?: string[],
    clickable?: boolean,
}

export interface DataTableActionDefinition {
    custom: (row: any) => {},
    visibleForUserRole?: string[],
}

class DataTable extends Component<Props> {

    public state: {
        redirectLink: string,
    } = {
        redirectLink: '',
    }

    printColumnValue = (row: any, column: DataTableColumnDefinition) => {
        if (column.customValue) {
            return column.customValue(row);
        }
        let value = row;
        if (column.accessor.indexOf('.') > 0) {
            const accessors = column.accessor.split('.');
            accessors.forEach((item: string) => {
                value = value[item];
            })
        } else {
            value = row[column.accessor];
        }
        if (column.formatter) {
            return column.formatter(value);
        }
        return value;
    }

    redirectLink = (row: any) => {
        if (typeof this.props.rowClickEvent !== 'undefined') {
            this.props.rowClickEvent(row);
        }
        if (typeof this.props.rowClickLink === 'undefined') {
            return;
        }
        this.setState({
            redirectLink: this.props.rowClickLink(row),
        })
    }

    render() {
        if (typeof this.props.show !== 'undefined' && !this.props.show) {
            return '';
        }

        if (this.state.redirectLink.length > 0) {
            return <Redirect to={this.state.redirectLink}/>
        }

        if (this.props.emptyTableMessage && !this.props.data.length) {
            return (
                <Alert variant={"success"}>
                    {this.props.emptyTableMessage}
                </Alert>
            )
        }

        const currentUserRole = UserService.getInstance().getPretendedUserRole();
        const testUserRole = (item: DataTableColumnDefinition|DataTableActionDefinition) => {
            return !item.visibleForUserRole?.length || item.visibleForUserRole.indexOf(currentUserRole) >= 0;
        };
        let columns = this.props.columns.filter((column: DataTableColumnDefinition) => testUserRole(column));
        let actions = this.props.actions?.filter((action: DataTableActionDefinition) => testUserRole(action));

        return (
            <Table className={"table-hover table-responsive-lg"} size={"sm"}>
                <thead>
                <tr>
                    {columns.map((column: DataTableColumnDefinition) => (
                        <th>
                            {column.header}
                        </th>
                    ))}
                    {this.props.actions &&
                    <th>
                        {' '}
                    </th>
                    }
                </tr>
                </thead>
                <tbody>
                {this.props.data.map((row: any) => (
                    <tr key={row.id}>
                        {columns.map((column: DataTableColumnDefinition) => {
                            let onClick = (row: any) => {};
                            if (column.clickable !== false) {
                                onClick = this.redirectLink
                            }
                            return (
                                <td onClick={() => onClick(row)} style={{cursor: 'pointer'}}>
                                    {this.printColumnValue(row, column)}
                                </td>
                            );
                        })}
                        {actions && actions.map((action: DataTableActionDefinition) => (
                            <td>
                                {action.custom(row)}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        )
    }

}

export default DataTable;
