import Api from "../utils/Api";
import UUID from "../type/Uuid";

class ProjectTicketTaskRepository {

    static findByTicketId = (ticketId: UUID, filters: any = {}) => {
        const filterParams = new URLSearchParams();
        Object.keys(filters).forEach(key => filterParams.append(key, filters[key]));
        return Api.get('/project/tasks/' + ticketId + '/?' + filterParams.toString());
    }

    static add = (ticketId: UUID, title: string, description: string) => {
        return Api.post('/project/tasks/' + ticketId + '/', {
            title: title,
            description: description,
        });
    }

    static findById(taskId: UUID) {
        return Api.get('/project/task/' + taskId + '/');
    }

    static markAsDone(taskId: UUID) {
        return Api.post('/project/task/' + taskId + '/', {
            is_done: true,
        });
    }

    static markAsUndone(taskId: UUID) {
        return Api.post('/project/task/' + taskId + '/', {
            is_done: false,
        });
    }

    static close(taskId: UUID) {
        return Api.post('/project/task/' + taskId + '/', {
            is_closed: true,
        });
    }

    static reopen(taskId: UUID) {
        return Api.post('/project/task/' + taskId + '/', {
            is_closed: false,
        });
    }

    static updateTimeEstimate(taskId: UUID, value: number) {
        return Api.post('/project/task/' + taskId + '/', {
            time_estimate: value,
        });
    }

    static assignToUser(taskId: UUID, value: number) {
        return Api.post('/project/task/' + taskId + '/', {
            assigned_id: value,
        });
    }

    static acceptCost(taskId: UUID) {
        return Api.post('/project/task/' + taskId + '/', {
            cost_accepted: true,
        });
    }

    static findTasksToDo() {
        return Api.get('/project/task/todo/');
    }

    static findTasksNotPaid() {
        return Api.get('/project/task/not-paid/');
    }
}

export default ProjectTicketTaskRepository;
