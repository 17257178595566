export default class UUID {
    protected m_str: string;

    constructor(str: string = '') {
        this.m_str = str;

        const reg: RegExp = new RegExp("[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}", "i")
        if (this.m_str.length && !reg.test(this.m_str)) {
            throw new Error('Incorrect UUID: ' + this.m_str);
        }
    }

    toString() {
        return this.m_str;
    }
}