import React, {Component} from "react";
import {Alert, Button} from "react-bootstrap";
import BillingOrderRepository from "../../repository/BillingOrderRepository";
import ProjectTicketTask from "../../model/ProjectTicketTask";
import BillingOrder from "../../model/BillingOrder";
import UUID from "../../type/Uuid";

interface Props {
    project_ticket_tasks: ProjectTicketTask[],
    newOrderHandler: (order: BillingOrder) => void,
}

class OrderCreate extends Component<Props> {

    public state: {
        amount: number,
    } = {
        amount: 0,
    }

    calcAmount = () => {
        let amount = 0;
        for (const i in this.props.project_ticket_tasks) {
            amount += this.props.project_ticket_tasks[i].cost_amount;
        }
        if (this.state.amount !== amount) {
            this.setState({
                amount: amount,
            });
        }
        return amount;
    }

    orderCreate = () => {
        const project_ticket_task_ids = this.props.project_ticket_tasks.map((task: ProjectTicketTask) => {
            return task.id;
        }).filter((task_id: UUID) => {
            return task_id;
        });
        BillingOrderRepository.create(project_ticket_task_ids).then((order: BillingOrder) => {
            this.props.newOrderHandler(order);
        });
    }

    render() {
        return (
            <>
                <Alert variant={"default"} className={"text-right"}>
                    <hr/>
                    Kwota zamówienia:
                    <strong> {this.calcAmount() / 100} zł </strong>
                    <Button variant={"primary"} disabled={this.state.amount <= 0} onClick={this.orderCreate}>
                        Utwórz zamówienie
                    </Button>
                </Alert>
            </>
        );
    }
}

export default OrderCreate;
