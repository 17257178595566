import Api from "../utils/Api";
import UUID from "../type/Uuid";

class BillingOrderRepository {

    static findAll = () => {
        return Api.get('/billing/order/');
    }

    static create = (project_ticket_task_ids: UUID[]) => {
        return Api.post('/billing/order/', {
            project_ticket_task_ids: project_ticket_task_ids.join(','),
        });
    }

    static delete = (order_id: UUID) => {
        return Api.delete('/billing/order/' + order_id + '/');
    }

}

export default BillingOrderRepository;
