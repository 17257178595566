import Project from "./Project";
import UUID from "../type/Uuid";
import User from "./User";
import ProjectTicketFile from "./ProjectTicketFile";
import {Badge} from "react-bootstrap";
import ProjectTicketLabel from "./ProjectTicketLabel";

class ProjectTicket {
    id: UUID = new UUID();
    title: string = '';
    description: string = '';
    labels: ProjectTicketLabel[] = [];
    cost_accepted: boolean = false;
    cost_amount: number = 0;
    cost_time: number = 0;
    created: string = '';
    updated: string = '';
    priority: number = 0;
    project: Project = new Project();
    assigned: User = new User();
    author: User = new User();
    time_remaining: number = 0;
    time_estimate: number = 0;
    time_spent: number = 0;
    progress_percentage: number = 0;
    is_closed: boolean = false;
    files: ProjectTicketFile[] = [];

    static getPriorityDesc(priority: number) {
        const PRIORITY: { [key: number]: any } = {
            10: <Badge variant={"secondary"}>↓ low</Badge>,
            20: <Badge variant={"info"}>⇅ normal</Badge>,
            30: <Badge variant={"warning"}>↑ high</Badge>,
            40: <Badge variant={"danger"}>↯↯ critical</Badge>,
        }
        return PRIORITY[priority]
    }
}

export default ProjectTicket;
