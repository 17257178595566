import Api from "./Api";
import User from "../model/User";

export default class UserService {

    private static instance: UserService;

    public static getInstance = (): UserService => {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    private user: User = new User();
    private permissions: string[] = [];

    login = (username: string, password: string) => {
        return Api.post(
            '/token-auth/',
            {username, password}
        )
            // return fetch(API_URL + '/token-auth/', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({username, password})
            // })
            // .then((response: Response) => {
            //     if (response.ok) {
            //         return response.json();
            //     } else {
            //         console.error('Something went wrong');
            //     }
            // })
            .then((json: any) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', json.token);
                this.user = json.user;
                this.permissions = json.permissions;
                return this.user;
            });
    }

    logout = (): void => {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        document.location.reload();
    }

    getDetails = () => {
        if (!localStorage.getItem('token') || this.isLoggedIn()) {
            return Promise.resolve(this.user);
        }
        return Api.get('/user/current/').then((json: { user: User, permissions: string[] }) => {
            this.user = json.user;
            this.permissions = json.permissions;
            // console.log(this.user, this.permissions);
            return json.user;
        });
    }

    isLoggedIn = (): boolean => {
        return (this.user?.username.length > 0);
    }

    hasPerm = (permName: string): boolean => {
        return (this.permissions.indexOf(permName) >= 0);
    }

    getUser(): User {
        return this.user;
    }

    pretendUserRole = (userRoleName: string) => {
        localStorage.setItem('pretendUserRole', userRoleName);
        return Promise.resolve();
    }

    getPretendedUserRole = () => {
        const pretendUserRole = localStorage.getItem('pretendUserRole');
        if (pretendUserRole && pretendUserRole.length > 0) {
            return pretendUserRole;
        }
        return (this.user.is_staff ? 'staff' : 'customer');
    }
}
