import React, {Component} from "react";
import ProjectTicketTaskRepository from "../repository/ProjectTicketTaskRepository";
import ProjectTicketTask from "../model/ProjectTicketTask";
import {Alert, Badge, Container} from "react-bootstrap";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import Formatter from "../utils/Formatter";
import UserRoleStaff from "../components/UserRoleStaff";
import ProjectTicket from "../model/ProjectTicket";
import {Link} from "react-router-dom";
import UserRoleCustomer from "../components/UserRoleCustomer";
import UUID from "../type/Uuid";

class ToDo extends Component<any> {

    public state: {
        tasks: ProjectTicketTask[],
        loaded: boolean,
    } = {
        tasks: [],
        loaded: false,
    };

    componentDidMount() {
        this.loadTasks();
    }

    loadTasks = () => {
        ProjectTicketTaskRepository.findTasksToDo()
            .then((tasks: ProjectTicketTask) => this.setState({
                tasks: tasks,
                loaded: true,
                cache: Date.now(),
            }));
    }

    acceptCost = (event: any, taskId: UUID) => {
        event.preventDefault();

        ProjectTicketTaskRepository.acceptCost(taskId).then((task: ProjectTicketTask) => {
            let tasks = this.state.tasks;
            for (const i in tasks) {
                if (tasks[i].id === task.id) {
                    delete tasks[i];
                    break;
                }
            }
            this.setState({
                tasks: tasks,
            });
        });
    }

    acceptCostAll = (event: any) => {
        event.preventDefault();

        for (const i in this.state.tasks) {
            if (this.state.tasks[i].cost_amount > 0 && !this.state.tasks[i].cost_accepted) {
                this.acceptCost(event, this.state.tasks[i].id);
            }
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loader/>
        }

        return (
            <>
                <Container fluid className={"mt-3"}>

                    <UserRoleCustomer>
                        <h1>
                            Wyceny do akceptacji
                            {' '}
                            {this.state.tasks.length > 0 &&
                            <Link to={'#'} onClick={this.acceptCostAll} className={"btn btn-primary btn-sm"}>
                                Akceptuj wszystkie wyceny
                            </Link>
                            }
                        </h1>

                        {this.state.tasks.length > 0 &&
                        <Alert variant={"info"}>
                            Programiści nie rozpoczną prac dopóki nie akceptujesz wycen.
                        </Alert>
                        }

                        {this.state.tasks.length === 0 &&
                        <Alert variant={"info"}>
                            Nie ma żadnych oczekujących wycen do akceptowania.
                        </Alert>
                        }
                    </UserRoleCustomer>

                    <UserRoleStaff>
                        <h1>Twoje zadania</h1>
                    </UserRoleStaff>

                    <DataTable
                        show={this.state.tasks.length > 0}
                        columns={[
                            {
                                header: "Projekt",
                                accessor: "title",
                                customValue: ((task: ProjectTicketTask) => {
                                    return (
                                        <>
                                            <small>
                                                <Link to={`/project/${task.ticket.project.id}`}>
                                                    {task.ticket.project.title}
                                                </Link>
                                                {' / '}
                                                <Link to={`/project/ticket/${task.ticket.id}`}>
                                                    {task.ticket.title}
                                                </Link>
                                            </small>
                                            <br/>
                                            <strong>{task.title}</strong>
                                        </>
                                    )

                                })
                            },
                            {
                                header: "Status",
                                accessor: "ticket.priority",
                                customValue: ((task: ProjectTicketTask) => {
                                    return (
                                        <>
                                            {task.is_closed ?
                                                <Badge variant={"default"}>
                                                    Zamknięte
                                                </Badge>
                                                : task.is_done ?
                                                    <Badge variant={"success"}>
                                                        Zrobione
                                                    </Badge>
                                                    : (task.cost_accepted ?
                                                            <Badge variant={"primary"}>
                                                                W trakcie prac: {task.progress_percentage}%
                                                            </Badge>
                                                            : (task.time_estimate ?
                                                                    <Badge variant={"secondary"}>
                                                                        Oczekuje na akceptacje
                                                                    </Badge>
                                                                    :
                                                                    <Badge variant={"warning"}>
                                                                        Nie oszacowano czasu
                                                                    </Badge>
                                                            )
                                                    )
                                            }
                                        </>
                                    )
                                })
                            },
                            {
                                header: "Priorytet",
                                accessor: "ticket.priority",
                                formatter: ProjectTicket.getPriorityDesc,
                                visibleForUserRole: ['staff'],
                            },
                            {
                                header: "Zarejestrowano",
                                accessor: "time_spent",
                                formatter: Formatter.time,
                                visibleForUserRole: ['staff'],
                            },
                            {
                                header: "Szacowany",
                                accessor: "time_estimate",
                                formatter: Formatter.time,
                                visibleForUserRole: ['staff'],
                            },
                            {
                                header: "Utworzone",
                                accessor: "created",
                                formatter: Formatter.dateTime,
                            },
                            {
                                header: "Koszt",
                                accessor: "cost_amount",
                                formatter: Formatter.money,
                                visibleForUserRole: ['customer'],
                            },
                        ]}
                        rowClickLink={(task: ProjectTicketTask) => {
                            return `/project/ticket/task/${task.id}`
                        }}
                        data={this.state.tasks}
                        actions={[
                            {
                                custom: ((task: ProjectTicketTask) => {
                                    return (
                                        <Link to={'#'} onClick={(e) => this.acceptCost(e, task.id)}>
                                            Akceptuj wycenę
                                        </Link>
                                    )
                                }),
                                visibleForUserRole: ['customer'],
                            }
                        ]}
                    />
                </Container>
            </>
        );
    }
}

export default ToDo;