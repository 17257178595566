import React, {Component} from "react";
import {Toast} from "react-bootstrap";
import Api from "../utils/Api";

class ServiceOnline extends Component<any> {

    public state: {
        online: boolean,
    } = {
        online: true,
    }

    private interval: number = 0;

    componentDidMount() {
        this.testIsOnline();
        this.interval = window.setInterval(this.testIsOnline.bind(this), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    testIsOnline() {
        Api.get('/service-online/').then(json => {
            this.setState({
                online: json.success,
            });
        }).catch(() => {
            this.setState({
                online: false,
            });
        });
    }

    render() {
        return (
            <>
                <Toast
                    style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        zIndex: 99999,
                    }} className={"m-3"} show={!this.state.online}>
                    <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                            Brak połączenia z serwerem
                        </strong>
                    </Toast.Header>
                    <Toast.Body>
                        <small>
                            Sprawdź połączenie z Internetem.
                            <br/>
                            <strong>Wprowadzane zmiany nie zostaną zapisane.</strong>
                            <br/>
                            Będziemy sprawdzać czy połączenie już działa.
                        </small>
                    </Toast.Body>
                </Toast>
            </>
        )
    }
}

export default ServiceOnline;
