import {Component} from "react";
import UserService from "../utils/UserService";
import {Button} from "react-bootstrap";

class PretendUserRole extends Component<any> {

    switchRoleAsCustomer = () => {
        this.switchRole('customer');
    }

    switchRoleAsStaff = () => {
        this.switchRole('staff');
    }

    // switchRoleAsSuperUser = () => {
    //     this.switchRole('superuser');
    // }

    private switchRole = (user_role: string) => {
        UserService.getInstance().pretendUserRole(user_role).then(() => document.location.reload());
    }

    render() {
        if (!UserService.getInstance().getUser().is_superuser) {
            return null;
        }
        let currentUserRole = UserService.getInstance().getPretendedUserRole();
        if (!currentUserRole) {
            currentUserRole = 'staff';
        }
        return (
            <>
                {currentUserRole === 'staff' &&
                <>
                    Widok pracownika aktywny
                    <Button onClick={this.switchRoleAsCustomer}
                            variant={"secondary"}
                            size={"sm"}>
                        Przełącz na widok klienta
                    </Button>
                </>
                }
                {currentUserRole === 'customer' &&
                <>
                    Widok klienta aktywny
                    <Button onClick={this.switchRoleAsStaff}
                            variant={"secondary"}
                            size={"sm"}>
                        Przełącz na widok pracownika
                    </Button>
                </>
                }
                {/*{' '}*/}
                {/*<Button onClick={this.switchRoleAsSuperUser}*/}
                {/*        disabled={currentUserRole == 'superuser'}*/}
                {/*        size={"sm"}>*/}
                {/*    Przełącz na widok administratora*/}
                {/*</Button>*/}
            </>
        );
    }
}

export default PretendUserRole;
