import Moment from "react-moment";
import FormatTime from "../components/FormatTime";

class Formatter {

    static dateTime = (value: string) => {
        return (
            <Moment format="HH:mm:ss DD.MM.YYYY">
                {value}
            </Moment>
        )
    }

    static time = (value: string) => {
        return (
            <FormatTime>
                {value}
            </FormatTime>
        )
    }

    static money = (value: number) => {
        if (!value) {
            return '-'
        }

        return (
            <>
                {value / 100} zł
            </>
        )
    }

}

export default Formatter;