import {Component} from "react";
import UserService from "../utils/UserService";

class UserRoleCustomer extends Component<any> {

    render() {
        if (!UserService.getInstance().getUser().is_staff) {
            return this.props.children;
        }

        return '';
    }

}

export default UserRoleCustomer;