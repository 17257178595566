import {Component} from "react";
import UserService from "../utils/UserService";

class UserRoleStaff extends Component<any> {

    render() {
        if (UserService.getInstance().getUser().is_staff) {
            return this.props.children;
        }

        return '';
    }

}

export default UserRoleStaff;