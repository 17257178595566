import axios from "axios";
import {API_URL} from "../constants";
import UserService from "./UserService";

// axios.interceptors.response.use(
//     (response) => {
//         return response
//     },
//     (error) => {
//         return new Promise((resolve) => {
//             const originalRequest = error.config
//             const refreshToken = localStorage.getItem('refresh_token')
//             if (error.response && error.response.status === 401 &&
//                 error.config && !error.config.__isRetryRequest && refreshToken) {
//                 originalRequest._retry = true
//
//                 const response = fetch('http://localhost:8000/token-refresh/', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         refresh: refreshToken,
//                     }),
//                 })
//                     .then((res) => res.json())
//                     .then((res) => {
//                         localStorage.set(res.access, 'token')
//
//                         return axios(originalRequest)
//                     })
//                 resolve(response)
//             }
//
//             return Promise.reject(error)
//         })
//     },
// )

const Api = {
    fetch: function (url: string) {
        return this.get(url);
    },

    get: function (url: string) {
        const config = {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            },
            params: {
                pretendUserRole: UserService.getInstance().getPretendedUserRole()
            },
        }
        return axios.get(API_URL + url, config).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Something went wrong');
            }
        });
    },

    post: function (url: string, data: {} = {}, _config: {} = {}) {
        let config = {
            ..._config,
            headers: {
                "Authorization": `JWT ${localStorage.getItem('token')}`,
                "Content-Type": 'application/json',
            },
            params: {
                pretendUserRole: UserService.getInstance().getPretendedUserRole()
            },
        }
        // const pretendedUserRole = UserService.getInstance().getPretendedUserRole();
        // if (pretendedUserRole) {
        //     config.params.pretendUserRole = pretendedUserRole;
        // }
        return axios.post(API_URL + url, data, config).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Something went wrong');
            }
        });
    },

    patch: function (url: string, data: [] = []) {
        const config = {
            headers: {
                "Authorization": `JWT ${localStorage.getItem('token')}`,
                "Content-Type": 'application/json',
            }
        }
        return axios.patch(API_URL + url, data, config).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Something went wrong');
            }
        });
    },

    delete: function (url: string) {
        const config = {
            headers: {
                "Authorization": `JWT ${localStorage.getItem('token')}`,
                "Content-Type": 'application/json',
            },
            params: {
                pretendUserRole: UserService.getInstance().getPretendedUserRole()
            },
        }
        return axios.delete(API_URL + url, config).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Something went wrong');
            }
        });
    },

}

export default Api;
