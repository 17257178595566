import React, {Component} from "react";
import ProjectTicketTask from "../../model/ProjectTicketTask";
import {Link} from "react-router-dom";
import Formatter from "../../utils/Formatter";
import DataTable from "../../components/DataTable";
import {Input} from "reactstrap";

interface Props {
    tasks: ProjectTicketTask[],
    selectProjectTasksSelectedToPay: (tasks: ProjectTicketTask[]) => void,
}

class ProjectTasksNotPaid extends Component<Props> {

    public state: {
        selectedTasks: ProjectTicketTask[],
    } = {
        selectedTasks: [],
    };

    componentDidMount() {
        this.setState({
            selectedTasks: [...this.props.tasks],
        });
    }

    setState(state: any, callback?: () => void) {
        super.setState(state, callback);
        this.props.selectProjectTasksSelectedToPay(state.selectedTasks);
    }

    toggleTaskSelect = (task: ProjectTicketTask) => {
        const index = this.state.selectedTasks.indexOf(task);
        let selectedTasks = this.state.selectedTasks;
        if (index >= 0) {
            delete selectedTasks[index];
        } else {
            this.state.selectedTasks.push(task)
        }
        this.setState({
            selectedTasks: selectedTasks,
        });
    }

    toggleSelectAllTasks = () => {
        if (this.state.selectedTasks.length === this.props.tasks.length) {
            this.setState({
                selectedTasks: [],
            });
        } else {
            this.setState({
                selectedTasks: [...this.props.tasks],
            });
        }
    }

    render() {
        return (
            <>
                <DataTable
                    columns={[
                        {
                            header: "Projekt",
                            accessor: "",
                            customValue: ((task: ProjectTicketTask) => {
                                return (
                                    <>
                                        <small>
                                            <Link to={`/project/${task.ticket.project.id}`}>
                                                {task.ticket.project.title}
                                            </Link>
                                            {' / '}
                                            <Link to={`/project/ticket/${task.ticket.id}`}>
                                                {task.ticket.title}
                                            </Link>
                                        </small>
                                        <br/>
                                        <strong>{task.title}</strong>
                                    </>
                                )

                            })
                        },
                        {
                            header: "Utworzono",
                            accessor: "created",
                            formatter: Formatter.dateTime,
                        },
                        {
                            header: "Koszt",
                            accessor: "cost_amount",
                            formatter: Formatter.money,
                        },
                        {
                            header: (
                                <>
                                    <Input type={"checkbox"}
                                           checked={this.state.selectedTasks.length === this.props.tasks.length}
                                           onChange={() => this.toggleSelectAllTasks()}
                                    />
                                    {'.'}
                                </>
                            ),
                            accessor: "",
                            customValue: ((task: ProjectTicketTask) => {
                                return (
                                    <>
                                        <Input type={"checkbox"}
                                               checked={this.state.selectedTasks.indexOf(task) >= 0}
                                               onChange={() => this.toggleTaskSelect(task)}
                                        />
                                    </>
                                )
                            }),
                        },
                    ]}
                    data={this.props.tasks}
                    emptyTableMessage={"Świetnie! Nie ma żadnych nierozliczonych zadań projektowych."}
                />
            </>
        );
    }
}

export default ProjectTasksNotPaid;
