import logo from './logo.png';
import './App.css';
import React, {Component, lazy, Suspense} from "react";
import SideNav from "./components/SideNav";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from "react-bootstrap";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import UserService from "./utils/UserService";
import Loader from "./components/Loader";
import ServiceOnline from "./components/ServiceOnline";
import ToDo from "./routes/ToDo";
import Billing from "./routes/billing/Billing";

const NotFound404 = () => <div>Not found</div>;
// const NotFound404 = () => <Redirect path={"/"} />;
const ProjectListRoute = lazy(() => import('./routes/project/ProjectList'));
const ProjectViewRoute = lazy(() => import('./routes/project/ProjectView'));
const TicketViewRoute = lazy(() => import('./routes/project/ticket/TicketView'));
const TaskViewRoute = lazy(() => import('./routes/project/ticket/task/TaskView'));
const LoginRoute = lazy(() => import('./routes/login/Login'));
const IndexRoute = lazy(() => import('./routes/Index'));
const SearchRoute = lazy(() => import('./routes/Search'));

class App extends Component<any> {

    public state: {
        logged_in: boolean,
        search_query: string,
    } = {
        logged_in: false,
        search_query: '',
    }

    componentDidMount() {
        UserService.getInstance().getDetails().then((user: any) => {
            if (!user) {
                this.setState({
                    logged_in: false,
                });
                return;
            }
            this.setState({
                logged_in: (user?.username.length > 0),
            });
        }).catch(() => {
            this.setState({
                logged_in: false,
            });
            return;
        });
    }

    handle_logout = () => {
        UserService.getInstance().logout();
        this.setState({
            logged_in: false,
        });
    }

    render() {
        if (this.state?.logged_in === null) {
            return <Loader/>
        }

        let routes = null;
        if (this.state?.logged_in) {
            routes = (
                <Switch>
                    <Route exact path={["/"]} component={IndexRoute}/>
                    <Route exact path={["/search"]} component={SearchRoute}/>
                    <Route exact path={["/billing"]} component={Billing}/>
                    <Route exact path={["/project"]} component={ProjectListRoute}/>
                    <Route exact path={["/project/:projectUuid"]} component={ProjectViewRoute}/>
                    <Route exact path={["/project/:projectId/add-task"]} component={TicketViewRoute}/>
                    <Route exact path={["/project/ticket/:ticketId"]} component={TicketViewRoute}/>
                    <Route exact path={["/project/ticket/task/todo"]} component={ToDo}/>
                    <Route exact path={["/project/ticket/task/:taskId"]} component={TaskViewRoute}/>
                    <Route component={NotFound404}/>
                </Switch>
            );
        } else {
            routes = (
                <Switch>
                    <Route exact path={["/"]} component={IndexRoute}/>
                    <Route exact path={["/login"]} component={LoginRoute}/>
                    <Route component={NotFound404}/>
                </Switch>
            );
        }
        return (
            <Container fluid>
                <ServiceOnline/>
                <Row>
                    <Router basename="/">
                        <Col lg={2} xs={0} className="sidenav"
                             style={{position: "sticky", top: 0}}>
                            <img src={logo} className="img-fluid mt-3 mb-1"
                                 alt="logo"/>
                            <p className="mb-5 text-muted">
                                Service Management
                            </p>
                            <SideNav
                                loggedIn={this.state.logged_in}
                                handleLogout={this.handle_logout}
                            />
                        </Col>
                        <Col lg={10} xs={12}>
                            <Suspense fallback={<Loader/>}>
                                {routes}
                            </Suspense>
                        </Col>
                    </Router>
                </Row>
                {/*<div className="wrapper">*/}
                {/*    <h1>Bird List</h1>*/}
                {/*    <ul>*/}
                {/*        {birds.map(bird => (*/}
                {/*            <li key={bird.name}>*/}
                {/*                <h3>{bird.name}</h3>*/}
                {/*                <div>*/}
                {/*                    Views: {bird.views}*/}
                {/*                </div>*/}
                {/*            </li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </Container>
        );
    }
}

export default App;
