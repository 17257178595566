import React, {Component} from "react";
import ProjectTicketTask from "../../model/ProjectTicketTask";
import {Container, Tab, Tabs} from "react-bootstrap";
import ProjectTasksNotPaid from "./ProjectTasksNotPaid";
import OrderCreate from "./OrderCreate";
import OrderList from "./OrderList";
import BillingOrder from "../../model/BillingOrder";
import BillingOrderRepository from "../../repository/BillingOrderRepository";
import ProjectTicketTaskRepository from "../../repository/ProjectTicketTaskRepository";
import Loader from "../../components/Loader";

class Billing extends Component<any> {

    public state: {
        projectTasksSelectedToPay: ProjectTicketTask[],
        projectTicketTasksNotPaid: ProjectTicketTask[],
        orders: BillingOrder[],
        activityTabKey: string,
        loaded_projectTicketTasksNotPaid: boolean,
        loaded_orders: boolean,
    } = {
        projectTasksSelectedToPay: [],
        projectTicketTasksNotPaid: [],
        orders: [],
        activityTabKey: 'project_ticket_tasks',
        loaded_projectTicketTasksNotPaid: false,
        loaded_orders: false,
    };

    selectProjectTasksSelectedToPay = (tasks: ProjectTicketTask[]) => {
        this.setState({
            projectTasksSelectedToPay: tasks,
        });
    }

    componentDidMount() {
        this.refresh();
    }

    newOrderHandler = (order: BillingOrder) => {
        this.refresh();
    }

    refresh = () => {
        this.loadOrders();
        this.loadProjectTicketTasksNotPaid();
    }

    loadOrders = () => {
        this.setState({
            loaded_orders: false,
        })
        BillingOrderRepository.findAll()
            .then((orders: BillingOrder[]) => this.setState({
                orders: orders,
                loaded_orders: true,
            }));
    }

    loadProjectTicketTasksNotPaid = () => {
        this.setState({
            loaded_projectTicketTasksNotPaid: false,
        })
        ProjectTicketTaskRepository.findTasksNotPaid()
            .then((tasks: ProjectTicketTask[]) => this.setState({
                projectTicketTasksNotPaid: tasks,
                loaded_projectTicketTasksNotPaid: true,
            }));
    }

    render() {
        if (!this.state.loaded_projectTicketTasksNotPaid || !this.state.loaded_orders) {
            return <Loader/>
        }

        return (
            <>
                <Container fluid className={"mt-3"}>
                    <h1>Rozliczenia</h1>

                    <Tabs
                        activeKey={this.state.activityTabKey}
                        onSelect={(k) => this.setState({activityTabKey: k})}
                    >
                        <Tab eventKey="orders" title="Twoje zamówienia">
                            <div className={"mt-3"}>
                                <OrderList orders={this.state.orders} refreshHandler={this.refresh} />
                            </div>
                        </Tab>
                        <Tab eventKey="project_ticket_tasks" title="Zadania projektowe nierozliczone">
                            <div className={"mt-3"}>
                                <ProjectTasksNotPaid
                                    tasks={this.state.projectTicketTasksNotPaid}
                                    selectProjectTasksSelectedToPay={this.selectProjectTasksSelectedToPay}
                                />
                                <OrderCreate
                                    project_ticket_tasks={this.state.projectTasksSelectedToPay}
                                    newOrderHandler={this.newOrderHandler}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                </Container>
            </>
        );
    }

}

export default Billing;
