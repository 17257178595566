import React, {Component} from "react";
import {Nav} from "react-bootstrap";
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import UserService from "../utils/UserService";
import PretendUserRole from "./PretendUserRole";
import UserRoleCustomer from "./UserRoleCustomer";
import UserRoleStaff from "./UserRoleStaff";

class SideNav extends Component<any> {

    static propTypes = {
        loggedIn: PropTypes.bool.isRequired,
        handleLogout: PropTypes.func.isRequired,
    }

    render() {
        const logged_out_nav = (
            <Nav defaultActiveKey="/" className="flex-column">
                <Nav.Link>
                    <Link to="/">Home</Link>
                </Nav.Link>
                <Nav.Link>
                    <Link to="/login">Zaloguj</Link>
                </Nav.Link>
            </Nav>
        );

        const logged_in_nav = (
            <Nav defaultActiveKey="/" className="flex-column">
                <Nav.Link>
                    <Link to="/">Home</Link>
                </Nav.Link>
                <Nav.Link>
                    <Link to="/project/ticket/task/todo">
                        <UserRoleStaff>
                            Twoje zadania
                        </UserRoleStaff>
                        <UserRoleCustomer>
                            Wyceny do akceptacji
                        </UserRoleCustomer>
                    </Link>
                </Nav.Link>
                <Nav.Link>
                    <Link to="/project">Projekty</Link>
                </Nav.Link>
                <Nav.Link>
                    <UserRoleCustomer>
                        <Link to="/billing">
                            Rozliczenia
                        </Link>
                    </UserRoleCustomer>
                </Nav.Link>
                <hr />
                Zalogowany jako {UserService.getInstance().getUser().username}
                <Nav.Link>
                    <Link to="/" onClick={this.props.handleLogout}>Wyloguj</Link>
                </Nav.Link>
                <hr />
                <PretendUserRole />
            </Nav>
        );

        return this.props.loggedIn ? logged_in_nav : logged_out_nav;
    }
}

export default SideNav;