import React, {Component} from "react";
import BillingOrder from "../../model/BillingOrder";
import DataTable from "../../components/DataTable";
import Formatter from "../../utils/Formatter";
import BillingOrderItem from "../../model/BillingOrderItem";
import {Badge} from "react-bootstrap";

interface Props {
    order: BillingOrder,
}

class OrderView extends Component<Props> {

    render() {
        return (
            <>
                <h3>
                    Zamówienie {this.props.order.id}
                    {' '}
                    {this.props.order.is_paid &&
                    <Badge variant={"success"}>
                        Opłacone
                    </Badge>
                    }
                    {!this.props.order.is_paid &&
                    <Badge variant={"danger"}>
                        Nieopłacone
                    </Badge>
                    }
                </h3>

                <DataTable
                    columns={[
                        // {
                        //     header: "ID",
                        //     accessor: "id",
                        // },
                        {
                            header: "Zadanie",
                            accessor: "",
                            customValue: ((item: BillingOrderItem) => {
                                if (item.project_ticket_task) {
                                    return (
                                        <>
                                            {item.project_ticket_task.ticket.project.title}
                                            {' > '}
                                            {item.project_ticket_task.ticket.title}
                                            {' > '}
                                            {item.project_ticket_task.title}
                                        </>
                                    );
                                }
                                return '-';
                            }),
                        },
                        // {
                        //     header: "Utworzono",
                        //     accessor: "created",
                        //     formatter: Formatter.dateTime,
                        // },
                        {
                            header: "Koszt",
                            accessor: "amount",
                            formatter: Formatter.money,
                        },
                        // {
                        //     header: (
                        //         <>
                        //             <Input type={"checkbox"}
                        //                    checked={this.state.selectedTasks.length === this.props.tasks.length}
                        //                    onChange={() => this.toggleSelectAllTasks()}
                        //             />
                        //             {'.'}
                        //         </>
                        //     ),
                        //     accessor: "",
                        //     customValue: ((task: ProjectTicketTask) => {
                        //         return (
                        //             <>
                        //                 <Input type={"checkbox"}
                        //                        checked={this.state.selectedTasks.indexOf(task) >= 0}
                        //                        onChange={() => this.toggleTaskSelect(task)}
                        //                 />
                        //             </>
                        //         )
                        //     }),
                        // },
                    ]}
                    data={this.props.order.items}
                    emptyTableMessage={"Świetnie! Nie ma żadnych nierozliczonych zadań projektowych."}
                />
            </>
        );
    }
}

export default OrderView;
