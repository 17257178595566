import React from "react";

const FormatTime = (props: React.ComponentProps<any>): JSX.Element => {

    const input = props?.children?.toString()
        ? parseInt(props?.children?.toString()) : 0;
    const hours = Math.floor(input / 60);
    const minutes = input - (hours * 60);

    return (
        <>
            {hours < 0 ? '-' : ''}
            {hours >= 10 ? '' : '0'}{Math.abs(hours)}:{minutes >= 10 ? '' : '0'}{minutes}
        </>
    )
}

export default FormatTime;