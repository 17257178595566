import React, {Component} from "react";
import Formatter from "../../utils/Formatter";
import DataTable from "../../components/DataTable";
import BillingOrder from "../../model/BillingOrder";
import {Link} from "react-router-dom";
import {Badge, Button} from "react-bootstrap";
import OrderView from "./OrderView";
import BillingOrderRepository from "../../repository/BillingOrderRepository";

interface Props {
    orders: BillingOrder[],
    refreshHandler: () => void,
}

class OrderList extends Component<Props> {

    public state: {
        selectedOrder: BillingOrder | false,
    } = {
        selectedOrder: false,
    }

    actionDeleteOrder = (event: any, order: BillingOrder) => {
        event.preventDefault();
        BillingOrderRepository.delete(order.id).then(() => {
            this.props.refreshHandler();
        });
    }

    render() {
        if (this.state.selectedOrder) {
            return (
                <>
                    <Button onClick={() => this.setState({selectedOrder: false})} size={"sm"}>
                        &lt; wróć do listy zamówień
                    </Button>
                    <div className={"mt-3"}>
                        <OrderView order={this.state.selectedOrder}/>
                    </div>
                </>
            )
        }

        return (
            <>
                <DataTable
                    columns={[
                        {
                            header: "ID",
                            accessor: "id",
                        },
                        {
                            header: "Kwota",
                            accessor: "cost_amount",
                            formatter: Formatter.money,
                        },
                        {
                            header: "Status",
                            accessor: "is_paid",
                            customValue: ((order: BillingOrder) => {
                                return (
                                    <>
                                        {order.is_paid &&
                                        <Badge variant={"success"}>
                                            Opłacone
                                        </Badge>
                                        }
                                        {!order.is_paid &&
                                        <Badge variant={"danger"}>
                                            Nieopłacone
                                        </Badge>
                                        }
                                    </>
                                )
                            }),
                        },
                        {
                            header: "Utworzono",
                            accessor: "created",
                            formatter: Formatter.dateTime,
                        },
                    ]}
                    rowClickEvent={(order: BillingOrder) => this.setState({selectedOrder: order})}
                    data={this.props.orders}
                    actions={[
                        {
                            custom: ((order: BillingOrder) => {
                                return (
                                    <>
                                        {order.is_paid ||
                                        <>
                                            <Link to={'#'}>
                                                Opłać
                                            </Link>
                                            {' lub '}
                                            <Link to={'#'} onClick={(e) => this.actionDeleteOrder(e, order)}>
                                                Anuluj zamówienie
                                            </Link>
                                        </>
                                        }
                                    </>
                                )
                            }),
                        }
                    ]}
                    emptyTableMessage={"Nie masz żadnych zamówień."}
                />
            </>
        );
    }
}

export default OrderList;
